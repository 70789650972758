var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-lg-8 col-md-8 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-lg-12 col-12 mb-4 pb-2"
  }, [_c('div', {
    staticClass: "card bg-light job-box rounded shadow border-0 overflow-hidden"
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('h5', {
    staticClass: "text-center",
    staticStyle: {
      "text-transform": "uppercase"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("amendment.receiver-desc")) + " ")]), _c('form', {
    staticClass: "login-form mt-4"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-lg-12 text-right"
  }, [[0, 1].includes(_vm.amendment_status) ? _c('b-btn', {
    staticClass: "btn-sm btn-primary btn",
    on: {
      "click": _vm.OpenPenerimaModal
    }
  }, [_vm._v(_vm._s(_vm.$t("amendment.add-receiver")))]) : _vm._e()], 1)]), _c('div', {
    staticClass: "row mt-2"
  }, [_c('table', {
    staticClass: "table table-hover"
  }, [_c('thead', [_c('tr', [_c('th', {
    attrs: {
      "scope": "col"
    }
  }, [_vm._v(_vm._s(_vm.$t("index")))]), _c('th', {
    attrs: {
      "scope": "col"
    }
  }, [_vm._v(_vm._s(_vm.$t("fullname")))]), _c('th', {
    attrs: {
      "scope": "col"
    }
  }, [_vm._v(_vm._s(_vm.$t("mykad")) + " / " + _vm._s(_vm.$t("passport")))]), _c('th', {
    attrs: {
      "scope": "col"
    }
  }, [_vm._v(_vm._s(_vm.$t("relationship")))]), [0, 1, 4, 5].includes(_vm.amendment_status) ? _c('th', {
    attrs: {
      "scope": "col"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("action")) + " ")]) : _vm._e()])]), _c('tbody', [_vm._l(_vm.receiver_array, function (item, index) {
    return _c('tr', {
      key: index
    }, [_c('td', [_vm._v(_vm._s(index + 1))]), _c('td', [_vm._v(_vm._s(item.name))]), !item.passport_number ? _c('td', [_vm._v(_vm._s(item.ic_number))]) : _c('td', [_vm._v(_vm._s(item.passport_number))]), _c('td', [_vm._v(_vm._s(item.relationship))]), [0, 1, 4, 5].includes(_vm.amendment_status) ? _c('td', {
      staticClass: "text-left"
    }, [_c('div', {
      staticClass: "btn-group-vertical",
      attrs: {
        "role": "group",
        "aria-label": "Vertical button group"
      }
    }, [[0, 1, 4, 5].includes(_vm.amendment_status) ? _c('b-button', {
      staticClass: "btn btn-secondary btn-sm",
      attrs: {
        "tag": "button"
      },
      on: {
        "click": function ($event) {
          return _vm.editReceiver(item, index);
        }
      }
    }, [_vm._v(" " + _vm._s(_vm.$t("update")) + " ")]) : _vm._e(), [0, 1, 4].includes(_vm.amendment_status) ? _c('b-button', {
      staticClass: "btn btn-danger btn-sm",
      attrs: {
        "tag": "button"
      },
      on: {
        "click": function ($event) {
          return _vm.deleteReceiver(item.id, index);
        }
      }
    }, [_vm._v(" " + _vm._s(_vm.$t("remove")) + " ")]) : _vm._e()], 1)]) : _vm._e()]);
  }), _vm.receiver_array && _vm.receiver_array.length == 0 ? _c('tr', [_c('td', {
    staticClass: "text-center",
    attrs: {
      "colspan": "5"
    }
  }, [_c('h5', {
    staticClass: "mt-5 mb-5"
  }, [_vm._v(_vm._s(_vm.$t("no-data")))])])]) : _vm._e()], 2)])])])])]), _c('div', {
    staticClass: "row mt-3"
  }, [_c('div', {
    staticClass: "col-lg-12 text-right"
  }, [_c('div', {
    staticClass: "form-group"
  }, [[0, 1].includes(_vm.amendment_status) ? _c('router-link', {
    staticClass: "btn btn-primary",
    attrs: {
      "to": {
        name: 'pindaan.part_d-tidakalih',
        params: {
          wasiat_id: this.wasiat_id
        },
        query: {
          pengesahan: _vm.pengesahan
        }
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("save-next")))]) : _vm._e()], 1)])])])]), _c('b-modal', {
    ref: "modalpenerima",
    staticClass: "modal fade",
    attrs: {
      "title": _vm.titleModal,
      "ok-title": _vm.$t('save'),
      "cancel-title": _vm.$t('cancel'),
      "no-enforce-focus": true
    },
    on: {
      "ok": _vm.buttonModalRes,
      "cancel": _vm.hideModalReceiver
    }
  }, [_c('TambahPenerima', {
    attrs: {
      "form": _vm.formReceiver,
      "doc": _vm.saveFile
    },
    on: {
      "formAddPostcode": _vm.eventHandler
    }
  }), _c('div', {
    staticClass: "row pr-3 pl-3"
  }, [_c('div', {
    staticClass: "col-md-6"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', [_vm._v(_vm._s(_vm.$t("mykadcopy-front"))), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")])]), _c('div', {
    staticClass: "card rounded shadow bg-dark"
  }, [_c('div', {
    staticClass: "d-flex align-items-center justify-content-center bd-highlight",
    staticStyle: {
      "height": "150px"
    },
    on: {
      "click": function ($event) {
        return _vm.openModal('mykad-front');
      }
    }
  }, [_vm.mykad.frontUrl ? _c('label', {
    staticStyle: {
      "border-radius": "5px",
      "margin-bottom": "0",
      "background-position": "center",
      "background-repeat": "no-repeat",
      "background-size": "cover",
      "height": "100%",
      "width": "100%"
    },
    style: 'background-image: url(' + _vm.mykad.frontUrlDisplay + ');',
    attrs: {
      "for": "upload-photo-1"
    }
  }) : _vm._e(), !_vm.mykad.frontUrl ? _c('label', {
    staticClass: "upload-photo-dark text-center"
  }, [_c('span', [_vm._v(_vm._s(_vm.$t("choose.mykadfront")))])]) : _vm._e()])]), _vm.mykad.frontUrl ? _c('div', {
    staticClass: "d-flex justify-content-center"
  }, [!_vm.isbase64Front ? _c('b-button', {
    staticClass: "btn-sm btn-success m-1",
    on: {
      "click": function ($event) {
        return _vm.openIcFront('front');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("view")) + " ")]) : _vm._e()], 1) : _vm._e()])]), _c('div', {
    staticClass: "col-md-6"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', [_vm._v(_vm._s(_vm.$t("mykadcopy-back"))), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")])]), _c('div', {
    staticClass: "card rounded shadow bg-dark"
  }, [_c('div', {
    staticClass: "d-flex align-items-center justify-content-center bd-highlight",
    staticStyle: {
      "height": "150px"
    },
    on: {
      "click": function ($event) {
        return _vm.openModal('mykad-back');
      }
    }
  }, [_vm.mykad.backUrl ? _c('label', {
    staticStyle: {
      "border-radius": "5px",
      "margin-bottom": "0",
      "background-position": "center",
      "background-repeat": "no-repeat",
      "background-size": "cover",
      "height": "100%",
      "width": "100%"
    },
    style: 'background-image: url(' + _vm.mykad.backUrlDisplay + ');',
    attrs: {
      "for": "upload-photo-2"
    }
  }) : _vm._e(), !_vm.mykad.backUrl ? _c('label', {
    staticClass: "upload-photo-dark text-center"
  }, [_c('span', [_vm._v(_vm._s(_vm.$t("choose.mykadback")))])]) : _vm._e()])]), _vm.mykad.backUrl ? _c('div', {
    staticClass: "d-flex justify-content-center"
  }, [!_vm.isbase64Back ? _c('b-button', {
    staticClass: "btn-sm btn-success m-1",
    on: {
      "click": function ($event) {
        return _vm.openIcFront('back');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("view")) + " ")]) : _vm._e()], 1) : _vm._e()])])])], 1), _c('b-modal', {
    ref: "modalSelectfront",
    staticClass: "modal fade",
    attrs: {
      "id": "modalSelect",
      "title": "Please Select",
      "ok-title": "Mulakan",
      "hide-footer": ""
    },
    on: {
      "close": _vm.closeEkyc
    }
  }, [_c('div', [_c('label', {
    staticClass: "upload-photo-dark text-center btn-sm btn-primary",
    attrs: {
      "for": "upload-photo-2"
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.$t("upload")))])]), _c('input', {
    staticClass: "upload-photo-input",
    attrs: {
      "type": "file",
      "name": "photo",
      "id": "upload-photo-2",
      "accept": "image/*"
    },
    on: {
      "change": function ($event) {
        return _vm.filesChange($event.target.files, 'mykad-front');
      }
    }
  })]), _c('div', [_c('label', {
    staticClass: "upload-photo-dark text-center btn-sm btn-primary",
    on: {
      "click": function ($event) {
        return _vm.openmodalcapture('mykad-front');
      }
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.$t("capture-picture")))])])])]), _c('b-modal', {
    ref: "modalSelect",
    staticClass: "modal fade",
    attrs: {
      "id": "modalSelect",
      "title": "Please Select",
      "ok-title": "Mulakan",
      "hide-footer": ""
    },
    on: {
      "close": _vm.closeEkyc
    }
  }, [_c('div', [_c('label', {
    staticClass: "upload-photo-dark text-center btn-sm btn-primary",
    attrs: {
      "for": "upload-photo-1"
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.$t("upload")))])]), _c('input', {
    staticClass: "upload-photo-input",
    attrs: {
      "type": "file",
      "name": "photo",
      "id": "upload-photo-1",
      "accept": "image/*"
    },
    on: {
      "change": function ($event) {
        return _vm.filesChange($event.target.files, 'mykad-back');
      }
    }
  })]), _c('div', [_c('label', {
    staticClass: "upload-photo-dark text-center btn-sm btn-primary",
    on: {
      "click": function ($event) {
        return _vm.openmodalcapture('mykad-back');
      }
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.$t("capture-picture")))])])])]), _c('b-modal', {
    ref: "modalOcrFront",
    staticClass: "modal fade",
    attrs: {
      "id": "modalOcrFront",
      "title": "Capture Camera",
      "ok-title": "Mulakan",
      "hide-footer": ""
    }
  }, [!this.$isMobile() ? _c('div', {
    staticClass: "video-options"
  }, [_c('select', {
    staticClass: "form-control",
    attrs: {
      "name": "",
      "id": ""
    },
    on: {
      "change": function ($event) {
        return _vm.selectCamera($event);
      }
    }
  }, _vm._l(_vm.videoDevices, function (device) {
    return _c('option', {
      key: device.deviceId,
      domProps: {
        "value": device.deviceId
      }
    }, [_vm._v(" " + _vm._s(device.label) + " ")]);
  }), 0)]) : _vm._e(), this.$isMobile() ? _c('div', [_c('b-button', {
    staticClass: "btn-sm",
    on: {
      "click": _vm.mobileChangeCamera
    }
  }, [_vm._v(" " + _vm._s(_vm.mobileCamera))])], 1) : _vm._e(), _c('div', {
    attrs: {
      "id": _vm.selfie == null && !_vm.noCamera ? 'content' : 'content-ic'
    }
  }, [_c('video', {
    ref: "video",
    staticClass: "camera-stream",
    staticStyle: {
      "border-radius": "10px",
      "box-shadow": "0 3px 5px 0 rgba(47, 85, 212, 0.3)",
      "z-index": "0",
      "position": "relative"
    },
    attrs: {
      "playsinline": "",
      "autoplay": "",
      "width": "70%"
    }
  }), _c('canvas', {
    ref: "canvas",
    staticStyle: {
      "display": "none"
    }
  }), _c('p', [_c('img')])]), _c('button', {
    staticClass: "btn btn-primary btn-xsm w-100",
    on: {
      "click": function ($event) {
        return _vm.capture('mykad-front');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("take-mykad-front")) + " ")])]), _c('b-modal', {
    ref: "modalOcrBack",
    staticClass: "modal fade",
    attrs: {
      "id": "modalOcrBack",
      "title": "Capture Camera",
      "ok-title": "Mulakan",
      "hide-footer": ""
    }
  }, [!this.$isMobile() ? _c('div', {
    staticClass: "video-options"
  }, [_c('select', {
    staticClass: "form-control",
    attrs: {
      "name": "",
      "id": ""
    },
    on: {
      "change": function ($event) {
        return _vm.selectCamera($event);
      }
    }
  }, _vm._l(_vm.videoDevices, function (device) {
    return _c('option', {
      key: device.deviceId,
      domProps: {
        "value": device.deviceId
      }
    }, [_vm._v(" " + _vm._s(device.label) + " ")]);
  }), 0)]) : _vm._e(), this.$isMobile() ? _c('div', [_c('b-button', {
    staticClass: "btn-sm",
    on: {
      "click": _vm.mobileChangeCamera
    }
  }, [_vm._v(" " + _vm._s(_vm.mobileCamera))])], 1) : _vm._e(), _c('div', {
    attrs: {
      "id": _vm.selfie == null && !_vm.noCamera ? 'content' : 'content-ic'
    }
  }, [_c('video', {
    ref: "video",
    staticClass: "camera-stream",
    staticStyle: {
      "border-radius": "10px",
      "box-shadow": "0 3px 5px 0 rgba(47, 85, 212, 0.3)",
      "z-index": "0",
      "position": "relative"
    },
    attrs: {
      "playsinline": "",
      "autoplay": "",
      "width": "70%"
    }
  }), _c('canvas', {
    ref: "canvas",
    staticStyle: {
      "display": "none"
    }
  }), _c('p', [_c('img')])]), _c('button', {
    staticClass: "btn btn-primary btn-xsm w-100",
    on: {
      "click": function ($event) {
        return _vm.capture('mykad-back');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("take-mykad-back")) + " ")])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }